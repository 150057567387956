const calculateSpace = (gridHeight, length) => {
  const spaces = new Array(length).fill('');

  return spaces.map((space, index) => {
    const newSpace = index * gridHeight;

    return newSpace;
  });
};

const theme = {
  gridRowHeight: 6,
  breakpoints: ['30em', '42em', '48em', '64em', '80em'],
  //    0 - 479: small mobile(mobile first)
  //  480 - 671: large mobile(landscape)
  //  672 - 768: small tablet
  //  768 - 1023: large tablet/ small desktop
  // 1024 - 1279: desktop
  // 1280 - ∞: desktop(white space left right)
  colors: {
    text: '#000000',
    textInverted: '#fff',
    muted: '#8d99ae',
    background: '#fff',
    backgroundDark: '#202232',
    backgroundDarkAlt: '#171824',
    primary: '#fd244e',
    primaryHover: 'rgba(253, 36, 78, 0.1)',
    primaryActive: '#D71F43',
    secondary: '#2c2c2c',
    border: '#dadada',
    borderDark: '#171824',
    backgrounds: {
      light: '#fff',
      medium: '#edf2f4',
      dark: '#2b2d42',
    },
  },
  useCustomProperties: true,
  grids: {
    default: {
      maxWidth: [null, null, null, 134, 150],
      mx: ['10px', null, null, 'auto'],
    },
  },
  fonts: {
    body: 'Montserrat, system-ui, sans-serif',
    'heading-light': 'Montserrat, "Times New Roman", serif',
    'heading-bold': 'Montserrat, "Times New Roman", serif',
    'heading-semi': 'Montserrat, "Times New Roman", serif',
    monospace: 'Menlo, monospace',
    button: 'Heroic',
  },
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
    button: 500,
    'heading-bold': 600,
    'heading-semi': 500,
    'heading-light': 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontProperties: {
    'heading-bold': {
      descenderHeightScale: 0.124,
      capHeight: 0.81,
    },
    'heading-semi': {
      descenderHeightScale: 0.124,
      capHeight: 0.81,
    },
    heading: {
      descenderHeightScale: 0.124,
      capHeight: 0.81,
    },
    body: {
      descenderHeightScale: 0.14,
      capHeight: 0.9,
    },
    button: {
      descenderHeightScale: 0.158,
      capHeight: 0.6,
    },
  },
  radii: {
    button: 24,
  },
  zIndices: [0, 10, 20, 30, 40, 50],
  //          0   1   2   3   4   5   6   7
  fontSizes: [13, 15, 16, 18, 22, 26, 40, 48],
  space: [],
  links: {
    default: {
      textDecoration: 'none',
      '&:hover': { color: 'primary' },
    },

    navCallToAction: {
      variant: 'links.default',
      color: 'primary',
    },
    inverted: {
      variant: 'links.default',
      color: 'textInverted',
    },
  },
  text: {
    default: {
      hyphens: 'auto',
      variant: 'text.heading',
      fontFamily: 'heading-semi',
      fontWeight: 400,
      fontSize: ['12.857142857142858px', null, '15.714285714285715px'],
      lineHeight: '24px',

      ':before': {
        marginTop: ['-0.5957222222222222em', null, '-0.4253181818181817em'],
      },
      ':after': {
        marginBottom: ['-0.5787222222222222em', null, '-0.4083181818181817em'],
      },
    },

    heading: {
      hyphens: 'auto',
      fontFamily: 'heading-bold',
      fontWeight: 'heading-bold',
      padding: '0.05px 0',
      '::before': {
        content: "''",
        display: 'block',
        height: 0,
      },
      '::after': {
        content: "''",
        display: 'block',
        height: 0,
      },
    },
    subtitle: {
      variant: 'text.heading',
      fontFamily: 'heading-semi',
      fontWeight: 'heading-semi',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontSize: ['15.714285714285715px', null, '17.142857142857142px'],
      lineHeight: '24px',
      ':before': {
        marginTop: ['-0.4253181818181817em', null, '-0.3614166666666667em'],
      },
      ':after': {
        marginBottom: ['-0.4083181818181817em', null, '-0.3444166666666667em'],
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: ['25.714285714285715px', null, '48.57142857142858px'],
      lineHeight: ['36px', null, '60px'],
      ':before': {
        marginTop: ['-0.3604444444444444em', null, '-0.2771764705882352em'],
      },
      ':after': {
        marginBottom: ['-0.3434444444444444em', null, '-0.2601764705882352em'],
      },
    },
    h2: {
      variant: 'text.heading',
      fontSize: ['22.857142857142858px', null, '40px'],
      lineHeight: ['30px', null, '54px'],
      ':before': {
        marginTop: ['-0.3169375em', null, '-0.33474999999999994em'],
      },
      ':after': {
        marginBottom: ['-0.29993749999999997em', null, '-0.3177499999999999em'],
      },
    },
    h3: {
      variant: 'text.heading',
      fontFamily: 'heading-semi',
      fontWeight: 'heading-semi',
      fontSize: ['18.571428571428573px', null, '22.857142857142858px'],
      lineHeight: '30px',
      color: 'primary',

      ':before': {
        marginTop: ['-0.46888461538461534em', null, '-0.3169375em'],
      },
      ':after': {
        marginBottom: ['-0.45188461538461533em', null, '-0.29993749999999997em'],
      },
    },
    'h3:white': {
      variant: 'text.h3',
      color: 'white',
    },

    h4: {
      variant: 'text.heading',
      fontFamily: 'heading-light',
      fontWeight: 'heading-light',
      fontSize: ['17.142857142857142px', null, '18.571428571428573px'],
      lineHeight: ['24px', null, '30px'],

      ':before': {
        marginTop: ['-0.3614166666666667em', null, '-0.46888461538461534em'],
      },
      ':after': {
        marginBottom: ['-0.3444166666666667em', null, '-0.45188461538461533em'],
      },
    },

    h4Alt: {
      variant: 'text.heading',
      fontFamily: 'button',
      fontWeight: 'heading-semi',
      textTransform: 'uppercase',
      fontSize: ['21.422594142259413px', null, '27.13528591352859px'],
      lineHeight: ['24px', null, '30px'],
      ':before': {
        marginTop: ['-0.18993164062500004em', null, '-0.18206979851973687em'],
      },
      ':after': {
        marginBottom: ['-0.23485351562500004em', null, '-0.22699167351973687em'],
      },
    },
    navigationTitle: {
      variant: 'text.heading',
      textTransform: 'uppercase',
      fontFamily: 'heading-light',
      fontWeight: 'heading-light',
      fontSize: '15.714285714285715px',
      lineHeight: '24px',
      ':before': {
        marginTop: '-0.4253181818181817em',
      },
      ':after': {
        marginBottom: '-0.4083181818181817em',
      },
    },
    navigationTitleAlt: {
      variant: 'text.heading',
      fontFamily: 'button',
      fontWeight: 'heading-light',
      textTransform: 'uppercase',
      fontSize: '18.566248256624824px',
      lineHeight: '18px',
      ':before': {
        marginTop: '-0.1148850661057693em',
      },
      ':after': {
        marginBottom: '-0.15980694110576932em',
      },
    },
  },
  button: {
    small: {
      fontSize: ['fontSize.5'],
    },
    medium: {
      fontSize: ['fontSize.6'],
    },
    default: {
      borderRadius: 'button',
      borderWidth: 0,
      display: 'block',
      transition: 'box-shadow ease-in-out 0.2s, background-color  ease-in-out 0.2s',
      px: 3,
      pt: 1,
      pb: 2,
      textAlign: 'left',
      textDecoration: 'none',
      '& > a, & > div': {
        textDecoration: 'none',
      },
      '& span': {
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: 'textInverted',
      },
    },
    solid: {
      variant: 'button.default',
      backgroundColor: 'primary',
      borderColor: 'transparent',
      boxShadow: '2px 2px 5px 0 rgba(0,0,0,0);',

      '&:hover': {
        boxShadow: '2px 2px 5px 0 rgba(0,0,0,0.18);',
      },
      '&:active': {
        backgroundColor: 'primaryActive',
      },
    },
    outline: {
      variant: 'button.default',
      background: 'transparent',
      borderColor: 'primary',
      borderWidth: 1,
      span: {
        color: 'text',
      },

      '&:hover, &:focus': {
        backgroundColor: 'primaryHover',
        span: {
          color: 'text',
        },
      },
      '&:active': {
        backgroundColor: 'primary',
        span: {
          color: 'textInverted',
        },
      },
      // todo: access theme from within theme
      // py: `calc(${theme.space[2]}px - 1px)`,
    },
    text: {
      variant: 'button.default',
      px: 0,
      background: 'transparent',
      borderRadius: 0,
      svg: {
        marginLeft: '1px',
        marginRight: '-1px',
      },
      span: {
        color: 'text',
      },
    },
    fullWidth: {
      variant: 'button.default',
      width: '100%',
      borderRadius: 0,
      paddingLeft: 7,
      paddingRight: 7,
      margin: 0,
    },
  },
  header: {
    light: {
      bg: 'background',
      color: 'text',
      borderColor: 'border',
    },
    dark: {
      bg: 'backgroundDark',
      color: 'textInverted',
      borderColor: 'borderDark',
    },
  },

  layout: {
    container: {
      backgroundColor: 'backgrounds.light',
      color: 'text',
    },
    dark: {
      backgroundColor: 'backgrounds.dark',
      color: 'textInverted',
    },
    medium: {
      variant: 'layout.container',
      backgroundColor: 'backgrounds.medium',
    },
    light: {
      variant: 'layout.container',
    },
  },

  styles: {
    h6: {
      // maxWidth: '30vw',
      fontFamily: 'heading-bold',
      fontWeight: 'heading-bold',
      fontSize: '57.142857142857146px',
      lineHeight: '65px',
      padding: '0.05px 0',
      ':before': {
        content: "''",
        marginTop: '-0.22812499999999988em',
        display: 'block',
        height: 0,
      },
      ':after': {
        content: "''",
        marginBottom: '-0.21112499999999987em',
        display: 'block',
        height: 0,
      },
    },
    capsizeh5: {
      // maxWidth: '30vw',
      fontFamily: 'heading-bold',
      fontWeight: 'heading-bold',

      fontSize: '44.28571428571429px',
      lineHeight: '56px',
      padding: '0.05px 0',
      ':before': {
        content: "''",
        marginTop: '-0.2918870967741934em',
        display: 'block',
        height: 0,
      },
      ':after': {
        content: "''",
        marginBottom: '-0.2748870967741934em',
        display: 'block',
        height: 0,
      },
    },
    a: {},
    p: {
      fontSize: [0, null, 1],
      fontFamily: 'body',
      fontWeight: 'body',
      color: 'text',
      typeRowSpan: 4,
      strong: {
        fontWeight: '900',
      },
      em: {
        fontStyle: 'italic',
      },
      a: {
        color: 'primary',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    button: {
      letterSpacing: '2px',
      fontSize: 3,
      fontFamily: 'button',
      fontWeight: 'button',
      color: 'text',
      typeRowSpan: 5,
    },
    h1: {
      fontSize: [5, 5, 7],
      fontFamily: 'heading-bold',
      fontWeight: 'heading-bold',
      color: 'text',
      typeRowSpan: [6, 6, 10],

      // mb: 1,
    },
    h2: {
      fontSize: [6],
      fontFamily: 'heading-bold',
      fontWeight: 'heading-bold',
      color: 'text',
      typeRowSpan: [9],
    },
    h3: {
      fontSize: [4, 4, 3],
      fontFamily: 'heading-semi',
      fontWeight: 'heading-semi',
      color: 'primary',
      typeRowSpan: [5],
      mb: 4,
    },
    h4: {
      variant: 'text.h4',
      mb: 4,
    },
    h5: {
      variant: 'text.h5',
    },
    hr: {
      // borderWidth: (t) => t.gridRowHeight,
      height: 1,
      borderWidth: '0 !important',
      bg: 'rgba(0,0,0,0.05)',
    },
  },
};

const spaces = calculateSpace(theme.gridRowHeight, 201);

theme.space = {
  ...spaces,
  none: spaces[0],
  small: spaces[5],
  medium: spaces[10],
  large: spaces[15],
};

theme.sizes = calculateSpace(theme.gridRowHeight, 201);
export default theme;
